import React, { useState } from 'react'
import styles from './press.module.css'
import useInterval from './use_interval'
import logoBBH from './logo_bbh.png'
import logoTarget from './logo_target.png'
import logoIHM from './logo_ihm.png'
import logoNetflix from './logo_netflix.png'
import logoWalmart from './logo_walmart.png'
import logoNike from './logo_nike.png'
import logoMicrosoft from './logo_microsoft.png'
import logoIheartmedia from './logo_iheartmedia.png'
import logoGoogle from './logo_google.png'
import logoUrbanGeekz from './logo_urban_geekz.png'

const accolades = [
  {
    quote:
      'Envato Elements is one of those platforms that I logon to on a daily basis sometimes even when I don’t need to design anything. I get visual inspiration and feel empowered having this library of resources to make our ideas come to life.',
    source: 'BBH',
    author: 'Dillah Zakbah',
    logo: logoBBH
  },
  {
    quote:
      'Envato definitely comes in handy and makes my work so much easier when I need to tackle bigger projects that involve commercial work because the wide variety of video templates that are available are super easy to use. ',
    source: 'iHeartMedia',
    author: 'Atzimba Palomares Barriga',
    logo: logoIHM
  },
  {
    quote:
      'I always thought that things like Envato Elements are for the ‘creative geniuses’ in marketing or online. I never contemplated that I was trying to achieve the same results they were but in a different space. Now that I have found Envato Elements, I compare what I was producing to what I am now and it is quite evident that the investment was worthwhile.',
    source: 'Target Australia',
    author: 'Guy Barr',
    logo: logoTarget
  },
  {
    quote:
      'Over the years we have used Envato to access website themes and creative assets to build our news content platform, UrbanGeekz.com. We have also purchased everything from stock photos, digital templates, and other critical tools for our live website. I love the way Envato keeps us informed about theme updates and new trends in the industry. I would highly recommend Envato.',
    source: 'Founder & CEO',
    author: 'Kunbi Tinuoye',
    logo: logoUrbanGeekz
  }
]

export default function Press() {
  const [activeAccolade, setActiveAccolade] = useState(3)

  useInterval(() => {
    setActiveAccolade(activeAccolade === accolades.length - 1 ? 0 : activeAccolade + 1)
  }, 5000)

  return (
    <div className={styles.root}>
      <div className={styles.accolades}>
        {accolades.map(({ quote, source, author, logo }, i) => {
          // We want the longest accolade to determine the height
          // So it gets a special className, while the rest are absolutely positioned
          let blockquoteClass
          if (i === 3) {
            blockquoteClass = i === activeAccolade ? styles.sizerAccolade : styles.hiddenSizerAccolade
          } else {
            blockquoteClass = i === activeAccolade ? styles.accolade : styles.hiddenAccolade
          }

          return (
            <blockquote className={blockquoteClass} key={i}>
              <img loading='lazy' className={styles.logo} src={logo} alt={source} />
              <p className={styles.quote}>{quote}</p>
              <span className={styles.authorTagline}>{author}</span>, {source}
            </blockquote>
          )
        })}
      </div>

      <div className={styles.logos}>
        <img loading='lazy' className={styles.logoNetflix} src={logoNetflix} alt='Netflix' />
        <img loading='lazy' className={styles.logoWalmart} src={logoWalmart} alt='Wallmart' />
        <img loading='lazy' className={styles.logoNike} src={logoNike} alt='Nike' />
        <img loading='lazy' className={styles.logoMicrosoft} src={logoMicrosoft} alt='Microsoft' />
        <img loading='lazy' className={styles.logoIheartmedia} src={logoIheartmedia} alt='iHeartMedia' />
        <img loading='lazy' className={styles.logoGoogle} src={logoGoogle} alt='Google' />
      </div>
    </div>
  )
}
